<template lang="pug">
    section#loader
        include ../../assets/svgs/loader-ippuc.svg
</template>

<script>
export default {
  name: 'component-loader'
}
</script>

<style lang="stylus" scoped src="./Loader.styl"></style>
