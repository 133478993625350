<template lang="pug">
	transition(name="component-aside")
		aside(v-if="$store.state.mensagem")#mensagem
			p Endereço copiado com sucesso!
</template>

<script>
export default {
	name: 'component-mensagem'
}
</script>

<style lang="stylus" scoped src="./Mensagem.styl"></style>
