<template lang="pug">
	footer#footer
		.ippuc
			.wrapper
				SvgIcon(data="@svgs/logo_ippuc_vertical.svg")
				//- nav.principal
					a(href="#") Mapas
					router-link(:to="{name: 'Noticias'}") Noticias
					a(href="#") Transparencia
					a(href="#") Imprensa
					a(href="#") Contatos
		#sitemap
			.wrapper
				.sitemap
					ul.tags
						li(v-for="item in $store.getters.footer").tag
							p {{ item.content.title.pt }}
							ul
								li(v-for="child in item.children")
									router-link(:to="{ name: child.browsers[0].module, params: { slug: child.browsers[0].slug } }", v-if="!child.isExternal && child.browsers") {{ child.title.pt }}
									a(:href="child.link.pt", target="_blank", rel="noopener noreferrer", v-else-if="child.isExternal && child.link")
										| {{ child.title.pt }}
										FontAwesomeIcon(:icon="icons.faArrowUpRightFromSquare")
									p(v-else) {{ child.title.pt }}

				.logos
					.enderecos
						.logo_pmc
							a(href="https://www.curitiba.pr.gov.br/" target="_blank")
								SvgIcon(data="@svgs/logo_pmc_full.svg", original)
						.endereco
							p
								FontAwesomeIcon(:icon="icons.faLocationDot")
								| Rua Bom Jesus, 669 - 80035-010 - Curitiba-PR
							p
								FontAwesomeIcon(:icon="icons.faPhone")
								| +55 41 3250-1414
							p
								FontAwesomeIcon(:icon="icons.faEnvelope")
								| ippuc@ippuc.org.br
					.appcwb
						.selo_unesco
							img(src="@images/selo_unesco.png", alt="Cidade Criativa da UNESCO em 2014")
						.logo_cwbApp
							SvgIcon(data="@svgs/logo_curitiba_app.svg", original)
						.lojas
							a(href="https://itunes.apple.com/br/app/curitiba-app/id1447887852?mt=8" target="_blank")
								SvgIcon(data="@svgs/apple-store.svg", original)
							a(href="https://play.google.com/store/apps/details?id=br.org.curitiba.ici.appcuritiba" target="_blank")
								SvgIcon(data="@svgs/google-play.svg", original)
				.footer
					p
						FontAwesomeIcon(:icon="icons.faExclamationCircle").icon
						| É #[strong proibida] a reprodução dos documentos e conteúdos técnicos sem a aprovação prévia.
					p IPPUC © 2022, todos os direitos reservados.
</template>

<script>
import { faArrowUpRightFromSquare, faEnvelope, faPhone, faLocationDot, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'component-footer',
  data () {
    return {
      icons: {
        faArrowUpRightFromSquare,
        faEnvelope,
        faPhone,
        faLocationDot,
        faExclamationCircle
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>
