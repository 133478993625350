const dictionary = {
	banner: 'Banner',
	browserProjects: 'ProjetosDestaques',
	content: 'Conteudo',
	image: 'Imagem',
	imageGallery: 'Galeria',
	image_content: 'ConteudoImagem',
	informations: 'Informacoes',
	linkBlock: 'AcessoRapido',
	magazineBlock: 'Arquivos',
	president: 'Presidentes',
	organograma: 'Organograma',
	'organograma-organizacional': 'OrganogramaOrganizacional'
}

const components = {
	AcessoRapido: () => import(/* webpackChunkName: "AcessoRapido" */ '@sections/Pagina/AcessoRapido/AcessoRapido'),
	Arquivos: () => import(/* webpackChunkName: "Arquivos" */ '@sections/Pagina/Arquivos/Arquivos'),
	Aside: () => import(/* webpackChunkName: "Aside" */ '@components/Aside/Aside'),
	AsideGaleria: () => import(/* webpackChunkName: "AsideGaleria" */ '@components/AsideGaleria/AsideGaleria'),
	Banner: () => import(/* webpackChunkName: "Banner" */ '@sections/Pagina/Banner/Banner'),
	BannerEquipe: () => import(/* webpackChunkName: "BannerEquipe" */ '@sections/Pagina/BannerEquipe/BannerEquipe'),
	BannerGaleria: () => import(/* webpackChunkName: "BannerGaleria" */ '@sections/Pagina/BannerGaleria/BannerGaleria'),
	BannerGeral: () => import(/* webpackChunkName: "BannerGeral" */ '@sections/Pagina/BannerGeral/BannerGeral'),
	BannerTexto: () => import(/* webpackChunkName: "BannerTexto" */ '@sections/Pagina/BannerTexto/BannerTexto'),
	CardInformacao: () => import(/* webpackChunkName: "CardInformacao" */ '@components/CardInformacao/CardInformacao'),
	Conteudo: () => import(/* webpackChunkName: "Conteudo" */ '@sections/Pagina/Conteudo/Conteudo'),
	ConteudoImagem: () => import(/* webpackChunkName: "ConteudoImagem" */ '@sections/Pagina/ConteudoImagem/ConteudoImagem'),
	Galeria: () => import(/* webpackChunkName: "Galeria" */ '@sections/Pagina/Galeria/Galeria'),
	GaleriaDestaques: () => import(/* webpackChunkName: "GaleriaDestaques" */ '@components/GaleriaDestaques/GaleriaDestaques'),
	GaleriaGrid: () => import(/* webpackChunkName: "GaleriaGrid" */ '@components/GaleriaGrid/GaleriaGrid'),
	GaleriaNav: () => import(/* webpackChunkName: "GaleriaNav" */ '@components/GaleriaNav/GaleriaNav'),
	Imagem: () => import(/* webpackChunkName: "Imagem" */ '@components/Imagem/Imagem'),
	Informacoes: () => import(/* webpackChunkName: "Informacoes" */ '@sections/Pagina/Informacoes/Informacoes'),
	Presidente: () => import(/* webpackChunkName: "Presidente" */ '@components/Presidente/Presidente'),
	PresidenteContent: () => import(/* webpackChunkName: "PresidenteContent" */ '@components/PresidenteContent/PresidenteContent'),
	Presidentes: () => import(/* webpackChunkName: "Presidentes" */ '@sections/Pagina/Presidentes/Presidentes'),
	ProjetosDestaques: () => import(/* webpackChunkName: "ProjetosDestaques" */ '@sections/Pagina/ProjetosDestaques/ProjetosDestaques'),
	Organograma: () => import(/* webpackChunkName: "Organograma" */ '@sections/Pagina/Organograma/Organograma'),
	OrganogramaOrganizacional: () => import(/* webpackChunkName: "OrganogramaOrganizacional" */ '@sections/Pagina/OrganogramaOrganizacional/OrganogramaOrganizacional'),
	OrganogramaBox: () => import(/* webpackChunkName: "OrganogramaBox" */ '@components/OrganogramaBox/OrganogramaBox'),
	Timeline: () => import(/* webpackChunkName: "Timeline" */ '@sections/Pagina/Timeline/Timeline'),
	TimelineHorizontal: () => import(/* webpackChunkName: "TimelineHorizontal" */ '@components/TimelineHorizontal/TimelineHorizontal'),
	TimelineInstitucional: () => import(/* webpackChunkName: "TimelineInstitucional" */ '@components/TimelineInstitucional/TimelineInstitucional'),
	TimelineVertical: () => import(/* webpackChunkName: "TimelineVertical" */ '@components/TimelineVertical/TimelineVertical'),
	TimelineContent: () => import(/* webpackChunkName: "TimelineContent" */ '@components/TimelineContent/TimelineContent')
}

const props = {
	contents: {
		type: [Object, Array],
		required: true
	},
	specialType: {
		type: String,
		default: null
	}
}

const makeFilesDownloadable = content => {
	return content.replace(/(href=")https?:\/\/\S*(\/storage\/\S*\.(zip|rar|7z)")/gi, '$1$2 download')
}

const parseContent = content => {
	let parsed = makeFilesDownloadable(content)
	return parsed
}

const excerptByWords = (text, totalWords = 40) => {
	const words = text.match(/\S+/g)
	return words.slice(0, totalWords).join(' ') + ' ...'
}

function shareContent(title, url) {
	if (navigator.share) {
		navigator.share({ title, url })
	} else if (navigator.clipboard) {
		navigator.clipboard.writeText(`${url}`)
	} else {
		const input = document.createElement('input')
		input.value = `${url}`
		input.style.height = 0
		input.style.visibility = 'hidden'
		input.style.opacity = 0
		input.style.position = 'absolute'
		body.appendChild(input)
		if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
			input.contentEditable = true
			input.readOnly = true
			const range = document.createRange()
			range.selectNodeContents(input)
			const selection = window.getSelection()
			selection.removeAllRanges()
			selection.addRange(range)
			input.setSelectionRange(0, 999999)
		} else {
			input.select()
		}
		document.execCommand('copy')
		input.parentNode.removeChild(input)
	}
	this.$store.commit('setMensagem', true)
	setTimeout(() => {
		this.$store.commit('setMensagem', false)
	}, 5000)
}

const getCompleteLink = (browser = null, link = null) => {
	if (browser) {
		const domain = `${window.location.protocol}//${window.location.host}`
		switch (browser.module) {
			case 'Servico': return `${domain}/servicos/${browser.slug}`
			case 'Noticia': return `${domain}/noticias/${browser.slug}`
			case 'Pagina':
			default: return `${domain}/${browser.slug}`
		}
	}
	if (link) { return link.pt }
	return ''
}

export {
	components,
	dictionary,
	excerptByWords,
	getCompleteLink,
	// makeFilesDownloadable,
	parseContent,
	props,
	shareContent,
}
