<template lang="pug">
    Aside(:ativo="contents != null" @close="close()")#asideGaleria
        .controls
            button.left(@click="changeImage(ativo - 1)")
                SvgIcon(data="@svgs/seta-ippuc.svg")
            button.right(@click="changeImage(ativo + 1)")
                SvgIcon(data="@svgs/seta-ippuc.svg")
        ul.items
            li(v-for="image, index in contents", :class="{'ativo': ativo == index}").item
                Imagem(:contents="image")
                p(v-if="getCaption(image)") {{ getCaption(image) }}
        ul.nav-items
            li(v-for="image, index in contents" @click="changeImage(index)", :class="{'ativo': ativo == index}" :style="style").nav-item
                Imagem(:contents="image")
</template>

<script>
import { components } from '@/mixins/components'

export default {
  name: 'component-aside-galeria',
  components,
  data () {
    return {
      style: {
        transform: 'translateX(0)'
      }
    }
  },
  computed: {
    contents () {
      return this.$store.state.asideGaleria.contents
    },
    ativo: {
      get () {
        return this.$store.state.asideGaleria.index
      },
      set (index) {
        this.$store.commit('setAsideGaleria', {
          contents: this.contents,
          index
        })
      }
    }
  },
  methods: {
    close () {
      this.$store.commit('setAsideGaleria', {
        index: 0,
        contents: null
      })
    },
    getCaption (image) {
      return image.desktop.caption || ''
    },
    changeImage (index) {
      if (index >= this.contents.length) {
        this.ativo = 0
      } else if (index < 0) {
        this.ativo = this.contents.length - 1
      } else {
        this.ativo = index
      }

      if (this.ativo <= this.contents.length - 4) {
        const gap = parseFloat(window.getComputedStyle(document.querySelector('.nav-items')).gap)
        const move = (document.querySelector('.nav-item').offsetWidth + gap) * this.ativo
        this.style.transform = `translateX(-${move}px)`
      } else if (this.ativo == this.contents.length - 1) {
        const gap = parseFloat(window.getComputedStyle(document.querySelector('.nav-items')).gap)
        const move = (document.querySelector('.nav-item').offsetWidth + gap) * (this.ativo - 3)
        this.style.transform = `translateX(-${move}px)`
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./AsideGaleria.styl"></style>
