import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

const base = document.querySelector('base')
const axios = Axios.create({
	baseURL: `${base.getAttribute('href')}/api`
})

export default new Vuex.Store({
	state: {
		asideGaleria: {
			index: 0,
			contents: null
		},
		loading: null,
		menuAtivo: false,
		mensagem: false,
		searchInPage: '',
		menus: {
			footer: {},
			header: {}
		}
	},
	getters: {
		footer (state) {
			return state.menus.footer.content || []
		},
		header (state) {
			return state.menus.header.content || []
		}
	},
	mutations: {
		setAsideGaleria (state, payload) {
			Vue.set(state, 'asideGaleria', payload)
		},
		setMenu (state, item) {
			Vue.set(state.menus, item.type, item)
		},
		setLoader (state, payload = null) {
			state.loading = payload
		},
		setMensagem (state, payload = null) {
			state.mensagem = payload
		},
		setSearchInPage (state, payload) {
			state.searchInPage = payload
		},
		toggleMenuAtivo (state, payload = null) {
			if (payload != null) { state.menuAtivo = payload } else { state.menuAtivo = !state.menuAtivo }
		}
	},
	actions: {
		loadMenus ({ commit }) {
			axios.get('menu')
				.then(response => {
					response.data.forEach(item => {
						commit('setMenu', item)
					})
				})
		},
		toggleLoader ({ commit, state }) {
			if (!state.loading) {
				const interval = setInterval(() => {
					if (state.loading) {
						clearInterval(state.loading)
						commit('setLoader', null)
					}
				}, 1000)
				commit('setLoader', interval)
			}
		}
	},
	modules: { }
})
