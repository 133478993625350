/**
 * Sitemap
 * 	Referência: https://github.com/cheap-glitch/vue-cli-plugin-sitemap#readme
 * 	Adicionar o seguinte na rota em que precise alterar manualmente os valores do sitemap, com os respectivos valores:
		meta: {
			sitemap: {
				lastmod: new Date(),
				priority: 0.8,
				changefreq: 'daily',
			}
		}
 */

let routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
		meta: { type: 'home' },
	},
	{
		path: '/noticias',
		name: 'Noticias',
		component: () => import(/* webpackChunkName: "Noticias" */ '@views/Noticias/Noticias'),
		meta: { type: 'news' },
	},
	{
		path: '/noticias/:slug',
		name: 'Noticia',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
		meta: { sitemap: { ignoreRoute: true } }
	},
	{
		path: '/busca',
		name: 'Busca',
		component: () => import(/* webpackChunkName: "ListagemGeral" */ '@views/ListagemGeral/ListagemGeral'),
		meta: { type: 'search' },
	},
	{
		path: '/servicos',
		name: 'Servicos',
		component: () => import(/* webpackChunkName: "Servicos" */ '@views/Servicos/Servicos'),
		meta: { type: 'list' },
	},
	{
		path: '/servicos/:slug',
		name: 'Servico',
		component: () => import(/* webpackChunkName: "Servico" */ '@views/Servico/Servico'),
		meta: { sitemap: { ignoreRoute: true } }
	},
	{
		path: '/o-ippuc',
		alias: ['institucional'],
		name: 'Institucional',
		component: () => import(/* webpackChunkName: "Institucional" */ '@views/Institucional/Institucional'),
	},
	{
		path: '/:slug',
		name: 'Pagina',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
		meta: { sitemap: { ignoreRoute: true } }
	},
]

export default routes
