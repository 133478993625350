<template lang="pug">
	header#header(ref="header")
		.contato
			ul.items.wrapper
				li.item
					a(href="tel:+554132501414")
						FontAwesomeIcon(:icon="icons.faPhone")
						span +55 41 3250-1414
				li.item
					a(href="mailto:ippuc@ippuc.org.br")
						FontAwesomeIcon(:icon="icons.faEnvelope")
						span ippuc@ippuc.org.br
		.apresentacao.wrapper
			button(@click="shareContent(page.text, page.link)", title="Compartilhar").share
				FontAwesomeIcon(:icon="icons.faShareNodes")
				span Compartilhar
			router-link(:to="{ name: 'Home'}", tag="h1").logo.ippuc
				| IPPUC
				SvgIcon(data="@svgs/logo_ippuc.svg", original)
			h2.logo.pmc
				a(href="https://www.curitiba.pr.gov.br/", target="_blank")
					| Prefeitura de Curitiba
					SvgIcon(data="@svgs/logo_pmc.svg")
			.busca
				form(@submit.prevent="send")
					.input(:class="{ 'open': openSearch }")
						input(id="input_search", name="input_search", v-model="formulario.search")
						button(type="submit")
							FontAwesomeIcon(:icon="icons.faMagnifyingGlass")
				button.btn-menu(@click="toggleMenu()")
					SvgIcon(data="@svgs/menu.svg" v-if="!ativo")
					FontAwesomeIcon(:icon="icons.faXmark" v-else)
		nav.menu.wrapper(:class="{'ativo': ativo}", ref="menu")
			router-link(:to="{ name: 'Home'}", tag="h1").logo.ippuc
				| IPPUC
				SvgIcon(data="@svgs/logo_ippuc.svg", original)
			ul.items.primary
				MenuItem(v-for="item in primaryLine", :item="item", :key="item.content.title.pt", :class="{'ativo': submenuAtivo == item.content.title.pt}", @toggleSubMenu="toggleSubMenu").item
			ul.items.secondary
				MenuItem(v-for="item in secondaryLine", :item="item", :key="item.content.title.pt", :class="{'ativo': submenuAtivo == item.content.title.pt}", @toggleSubMenu="toggleSubMenu").item
</template>

<script>
import { shareContent } from '@/mixins/components'
import { faPhone, faEnvelope, faMagnifyingGlass, faXmark, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import MenuItem from '@components/MenuItem/MenuItem.vue'

export default {
	name: 'component-header',
	components: {
		MenuItem,
	},
	data () {
		return {
			icons: {
				faPhone,
				faEnvelope,
				faMagnifyingGlass,
				faXmark,
				faShareNodes
			},
			formulario: {
				search: '',
			},
			submenuAtivo: null,
			collapsedClass: window.innerWidth > 1024 ? 'collapsed' : 'scroll',
		}
	},
	computed: {
		primaryLine() {
			return this.$store.getters.header.slice(0, 5)
		},
		secondaryLine() {
			return this.$store.getters.header.slice(5)
		},
		ativo() {
			return this.$store.state.menuAtivo
		},
		page() {
			let title = document.querySelector('title')
			return {
				link: window.location.href,
				title: title.textContent
			}
		},
		openSearch() {
			return this.formulario.search || false
		}
	},
	watch: {
		ativo: function() {
			if(this.ativo) {
				this.$refs.header.classList.remove(this.collapsedClass)
			} else {
				this.toggleSubMenu(null)
				if(window.scrollY > 50)
					this.$refs.header.classList.add(this.collapsedClass)
			}
		},
		'$store.state.searchInPage': function() {
			if (this.$store.state.searchInPage == '')
            	this.formulario.search = ''
        }
	},
	mounted() {
		if(window.scrollY >= 30)
			this.$refs.header.classList.add(this.collapsedClass)
		setTimeout(() => {
			window.addEventListener('scroll', this.scrollFunction)
		}, 250)
	},
	methods: {
		send() {
			if (this.$route.name == 'Home' || this.$route.name == 'Busca') {
				this.$router.push({ name: 'Busca', query: this.formulario })
				this.formulario.search = ''
			} else {
				this.$store.commit('setSearchInPage', this.formulario.search)
			}
		},
		toggleMenu(){
			this.$store.commit('toggleMenuAtivo')
		},
		toggleSubMenu(submenuTitle) {
			if (this.submenuAtivo == submenuTitle)
				this.submenuAtivo = null
			else
				this.submenuAtivo = submenuTitle
		},
		scrollFunction() {
			if(this.ativo)
				this.$refs.header.classList.remove(this.collapsedClass)
			else if(window.scrollY > 50)
				this.$refs.header.classList.add(this.collapsedClass)
			else
				this.$refs.header.classList.remove(this.collapsedClass)
		},
		shareContent,
	},
}
</script>

<style lang="stylus" scoped src="./Header.styl"></style>
