<template lang="pug">
    li.menu-item
        router-link(:to="{ name: pageModule, params: { slug: pageSlug } }", v-if="!external && browsers") {{ text.pt }}
        a(:href="link.pt", target="_blank", rel="noopener noreferrer", v-else-if="external && link")
            | {{ text.pt }}
            FontAwesomeIcon(:icon="icons.faArrowUpRightFromSquare")
        p(v-else) {{ text.pt }}
        SvgIcon(data="@svgs/seta-ippuc.svg" @click="$emit('toggleSubMenu', text.pt)", v-if="childrenCount").arrow
        ul(v-if="childrenCount").submenus
            component-menu-item(v-for="subitem in item.children", :item="subitem", :key="item.text").submenu
</template>

<script>
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'component-menu-item',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      icons: {
        faArrowUpRightFromSquare
      }
    }
  },
  computed: {
    children () {
      return this.item.children || []
    },
    childrenCount () {
      return this.children.length
    },
    hasContent () {
      return !!this.item.content
    },
    external () {
      if (this.hasContent) { return this.item.content.isExternal }
      return this.item.isExternal
    },
    browsers () {
      return this.item.browsers
    },
    link () {
      if (this.hasContent) { return this.item.content.link }
      return this.item.link
    },
    pageSlug () {
      if (this.browsers) { return this.browsers[0].slug }
      return null
    },
    pageModule () {
      if (this.browsers) { return this.browsers[0].module }
      return 'Pagina'
    },
    text () {
      if (this.hasContent) { return this.item.content.title }
      return this.item.title
    }
  }
}
</script>

<style lang="stylus" scoped src="./MenuItem.styl"></style>
