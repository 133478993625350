<template lang="pug">
	main#app
		Header
		article#page
			Loader(v-if="$store.state.loading && $route.name == 'Home'")
			router-view
		Footer
		RotacionarAparelho
		AsideGaleria(v-if="$store.state.asideGaleria.contents")
		Mensagem
</template>

<script>
import AsideGaleria from '@components/AsideGaleria/AsideGaleria.vue'
import Mensagem from '@components/Mensagem/Mensagem.vue'
import Footer from '@components/Footer/Footer'
import Header from '@components/Header/Header'
import Loader from '@components/Loader/Loader'
import RotacionarAparelho from '@components/RotacionarAparelho/RotacionarAparelho.vue'

export default {
  name: 'template-app',
  metaInfo () {
    return {
      title: 'IPPUC',
      meta: [
        { name: 'description', content: '' }
      ]
    }
  },
  components: {
    AsideGaleria,
    Footer,
    Header,
    Loader,
    Mensagem,
    RotacionarAparelho
  },
  created () {
    this.$store.dispatch('loadMenus')
    this.$store.dispatch('toggleLoader')
  }
}
</script>

<style lang="stylus" scoped src="./App.styl"></style>
